import React, { Component } from 'react';
import { Form, Col, Row, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';
import Modal from 'react-modal';


const customMessageStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        fontSize: '.8rem',
        borderWidth: 'thick'
    }
};

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            company: '',
            email: '',
            password: '',
            passwordConfirm: '',
            powerCalUser: false,
            cmrUser: false,
            validated: false,
            showAlert: false,
            alertMessage: '',
            messageType: '',
            redirect: false
        };
    }

    UNSAFE_componentWillMount = () => {
        if (sessionStorage.getItem("apiserver") === null) {
            fetch('getconfig', {
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    sessionStorage.setItem("apiserver", data.apiServer);
                })
                .catch(error => alert("Error occurred while obtaining configuration information."));
        }
    }

    onUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    }

    onFirstNameChange = (e) => {
        this.setState({ firstName: e.target.value });
    }

    onLastNameChange = (e) => {
        this.setState({ lastName: e.target.value });
    }

    onPhoneNumberChange = (e) => {
        this.setState({ phoneNumber: e.target.value });
    }

    onCompanyChange = (e) => {
        this.setState({ company: e.target.value });
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }
    onPasswordConfirmChange = (e) => {
        this.setState({ passwordConfirm: e.target.value });
    }

    onUserTypePowerCalChange = (e) => {
        this.setState({ powerCalUser: e.target.checked });
    }

    onUserTypeCMRChange = (e) => {
        this.setState({ cmrUser: e.target.checked });
    }

    closeMessageModal = () => {
        this.setState({ showMessageModal: false });
        if (this.state.redirect) {
            window.location.href = "login";
        }
    }

    submitForm = (e) => {
        e.preventDefault();

        //reset any erroneous state
        this.setState({
            showAlert: false,
            alertMessage: '',
            messageType: ''
        });

        //built in bootstrap form validation
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({ validated: true });
            return;
        }

        if (!this.state.powerCalUser && !this.state.cmrUser) {
            this.setState({
                showAlert: true,
                alertMessage: "Pleaes choose a user type",
                messageType: 'danger',
                validated: true
            });
            return;
        }

        //check if passwords match
        if (this.refs.password.value !== this.refs.passwordConfirm.value) {
            this.setState({
                showAlert: true,
                alertMessage: "Passwords do not match",
                messageType: 'danger',
                password: '',
                passwordConfirm: '',
                validated: true
            });
            return;
        }

        //validate the password against password policy
        var regEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
        if (!regEx.test(this.refs.password.value)) {
            this.setState({
                showAlert: true,
                alertMessage: "Password must contain 1 lowercase letter, one uppercase letter, 1 numeric character, and one special character",
                messageType: 'danger',
                password: '',
                passwordConfirm: '',
                validated: true
            });
            return;
        }

        let url = sessionStorage.getItem("apiserver") + "/admin/api/users/register";
        let newUser = {
            username: this.state.username,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            company: this.state.company,
            email: this.state.email,
            password: this.state.password,
            powerCalUser: this.state.powerCalUser ? 1 : 0,
            cmrUser: this.state.cmrUser ? 1 : 0
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newUser)
        })
            .then(response => { return response.json(); })
            .then((response) => {
                if (response.includes("Username already exists.") || response.includes("Email address already exists.")) {
                    this.setState({ showMessageModal: true, messageMessage: response, messageLabel: "" });
                }
                else if (response.status === 500) {
                    this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add user.", messageLabel: "Problem Adding New User" });
                } else {
                    this.setState({ redirect: true, showMessageModal: true, messageMessage: "You are now registered with the PowerCal application.", messageLabel: "User Successfully Added" });
                }
            })
            .catch(error => {
                this.setState({
                    showAlert: true,
                    alertMessage: "Error occurred while attempting to register user: " + error,
                    messageType: 'danger'
                });
            });
    }

    render() {
        return (
            <div className="container">
                <div>
                    <h1>Register</h1>
                    <hr />
                    {this.state.showAlert ? <Alerter alertMessage={this.state.alertMessage} variant={this.state.messageType} /> : null}
                    <Form noValidate validated={this.state.validated} method="post" onSubmit={this.submitForm}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>User Type</Form.Label>
                            <Col sm={4}>
                                <Form.Check onChange={this.onUserTypePowerCalChange} value={this.state.usertypePowerCal} label='PowerCal' />
                                <Form.Check onChange={this.onUserTypeCMRChange} value={this.state.usertypeCMR} label='CMR' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Username</Form.Label>
                            <Col sm={4}>
                                <Form.Control onChange={this.onUsernameChange} value={this.state.username} required type="text" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>First Name</Form.Label>
                            <Col sm={4}>
                                <Form.Control onChange={this.onFirstNameChange} value={this.state.firstName} required type="text" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Last Name</Form.Label>
                            <Col sm={4}>
                                <Form.Control onChange={this.onLastNameChange} value={this.state.lastName} required type="text" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Phone Number</Form.Label>
                            <Col sm={4}>
                                <Form.Control onChange={this.onPhoneNumberChange} value={this.state.phoneNumber} required type="tel" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Company (Optional)</Form.Label>
                            <Col sm={4}>
                                <Form.Control onChange={this.onCompanyChange} value={this.state.company} type="text" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Email</Form.Label>
                            <Col sm={4}>
                                <Form.Control onChange={this.onEmailChange} value={this.state.email} required type="email" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Password</Form.Label>
                            <Col sm={4}>

                                <Form.Control ref="password" onChange={this.onPasswordChange} value={this.state.password} required type="password" />

                            </Col>
                            <Col>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <MDBIcon icon="info-circle" />
                                </OverlayTrigger>

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Confirm Password</Form.Label>
                            <Col sm={4}>
                                <Form.Control ref="passwordConfirm" onChange={this.onPasswordConfirmChange} value={this.state.passwordConfirm} required type="password" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={{ span: 4, offset: 2 }}>
                                <Button type="submit">Register</Button>
                            </Col>
                        </Form.Group>
                        <input name="__RequestVerificationToken" type="hidden" value="CfDJ8OIb7bvZe65Io6XjFvEsOduniIa0d7O55vSqLiuruv_9dbunGIWXG9dVYmjWOM6XXSX8NOK7PFqgmsrawnEX_4cMOs_j3zYoXi4aKfckvXpdY5ahJ0328kMbvJiIDViqGg60zlPqRLib7OUO_RC4QiE" />
                    </Form>
                </div>
                <Modal isOpen={this.state.showMessageModal} style={customMessageStyles} contentLabel={this.state.messageLabel} >
                    <div>
                        <div className="row">
                            <div className="col-12 text-center mt-2 mb-3 font-weight-bold">
                                {this.state.messageMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 button-div text-center"><button onClick={this.closeMessageModal}>Ok</button></div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

function Alerter(props) {
    return (
        <Alert variant={props.variant}>
            {props.alertMessage}
        </Alert>
    );
}

function renderTooltip(props) {
    return <Tooltip {...props}>Password must contain 1 lowercase letter, one uppsercase letter, 1 numeric character, and one special character</Tooltip>;
}

export default Register;
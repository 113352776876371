import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import Checkbox from './Checkbox';
import '../css/site.css';

let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        fontSize: '.8rem'
    }
};

const customMessageStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        fontSize: '.8rem',
        borderWidth: 'thick'
    }
};

class Roles extends Component {
    static displayName = Roles.name;

    constructor(props) {
        super(props);
        let currentRole = { id: "", name: "", users: [], groups: [], deleteFlag: false };
        this.state = {
            showModal: false,
            showDeletedRoles: false,
            roles: [],
            currentRole: currentRole,
            allUsers: [],
            allGroups: [],
            allRoles: [],
            displayRoles: [],
            addEditRoleButtonLabel: "Update Role",
            loading: true,
            newRole: false,
            type: sessionStorage.getItem("adminType"),
            showMessageModal: false,
            messageMessage: "",
            messageLabel: ""
        };
    }

    UNSAFE_componentWillMount() {
        let roles = sessionStorage.getItem("roles").toLowerCase().split(",");
        if ((roles !== null && roles !== undefined)) {
            if (roles.indexOf("admin") > -1) {
                this.setState({ type: "all", displayGroupTypeCbs: true });
            } else {
                let type = "";
                if (roles.indexOf("powercal support administrator") > -1 && roles.indexOf("cmr support administrator") === -1) {
					type = "PowerCal";
                    this.setState({ type: type });
                } else if (roles.indexOf("cmr support administrator") > -1 && roles.indexOf("powercal support administrator") === -1) {
                    type = "CMR";
                    this.setState({ type: type });
                }
                this.fetchStuff(type);
            }
        }
    }

    fetchStuff = (type) => {
        let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));
        let rolesurl = sessionStorage.getItem("apiserver") + "/admin/api/Roles";

        let applicationType = "";
        //let adminRoles = sessionStorage.getItem("roles");
        //if (adminRoles.toLowerCase().includes("admin") && adminRoles.toLowerCase().includes("cmr administrator"))
        //    applicationType = "both";
        //else
        //    applicationType = adminRoles.toLowerCase().split(",")[0];

        fetch(rolesurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                let applicationTypeRoles = [];
                if (applicationType === "both") {
                    applicationTypeRoles = data;
                } else {
                    applicationTypeRoles = data.filter((role) => {
                        let found = false;
                        role.applicationTypes.forEach((appType, i) => {
                            if (appType.toLowerCase() === type.toLowerCase()) {
                                found = true;
                                return false; //break
                            }
                        });
                        return found;
                    });
                }
                let filteredRoles = applicationTypeRoles.filter(role => !role.deleteFlag);
                this.setState({ allRoles: data, displayRoles: filteredRoles, loading: false });
            });

        let groupsurl = sessionStorage.getItem("apiserver") + "/admin/api/Groups";
        fetch(groupsurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ allGroups: data, loading: false });
            });

        let usersurl = sessionStorage.getItem("apiserver") + "/admin/api/Users?applicationType=" + type;
        fetch(usersurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ allUsers: data });
            });

        sessionStorage.setItem("adminType", type);
    }

    componentDidMount = () => {
        Modal.setAppElement('body');
    }

    openModal = (role) => {
        if (role) {
            this.setState({ addEditRoleButtonLabel: "Update Role", currentRole: role, newRole: false, showModal: true });
        } else if (this.state.type !== 'all') {
            let role = {
                id: "",
                name: "",
                users: [],
                groups: [],
                deleteFlag: false
            };
            this.setState({ addEditRoleButtonLabel: "Add Role", currentRole: role, newRole: true, showModal: true });
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    closeMessageModal = () => {
        this.setState({ showMessageModal: false });
    }

    onRoleNameChange = (e) => {
        var currentRole = this.state.currentRole;
        currentRole.name = e.target.value;
        this.setState({ currentRole: currentRole });
    }

    onAdminTypeChange = (adminType) => {
        this.setState({ type: adminType });
        this.fetchStuff(adminType);
    }

    updateRole = (role) => {
        let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));
        let applicationTypes = [];
        applicationTypes.push(this.state.type);

        let changeRole = {
            id: role.id,
            name: role.name,
            deleteFlag: role.deleteFlag,
            users: role.users,
            groups: role.groups,
            applicationTypes: applicationTypes
        };
        let roleArrayIndex = 0;
        let currentRoles = [];
        let currentDisplayRoles = [];

        if (role.id !== "") { // update existing role
            let rolesurl = sessionStorage.getItem("apiserver") + "/admin/api/roles/";
            fetch(rolesurl + this.state.currentRole.id, {
                method: 'PUT',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }),
                body: JSON.stringify(changeRole)
            })
                .then(() => {
                    this.upsertRoleUsers(changeRole.id);
                    this.upsertRoleGroups(changeRole.id);
                })
                .then(data => {
                    if (changeRole.deleteFlag && !this.state.showDeletedRoles) {
                        //Remove from display if the delete chackbox was checked
                        currentRoles = [...this.state.displayRoles];
                        roleArrayIndex = this.state.displayRoles.findIndex(r => r.id === role.id);
                        currentRoles.splice(roleArrayIndex, 1);
                    } else if (changeRole.deleteFlag && this.state.showDeletedRoles) {
                        //This role already had delete checked, but the admin updated something else about it

                    } else {
                        currentRoles = [...this.state.displayRoles];
                        roleArrayIndex = this.state.displayRoles.findIndex(r => r.id === role.id);

                        //This role may have had its delete flag unchecked, which means that it wouldn't have been available
                        //in displayRoles to be found.  Add it to that array in that case.
                        if (roleArrayIndex === -1)
                            currentRoles.splice(this.getInsertionPoint('display', changeRole), 0, changeRole);
                        else
                            currentRoles[roleArrayIndex] = changeRole;
                    }
                    this.setState({ displayRoles: currentRoles });

                    currentRoles = [...this.state.allRoles];
                    roleArrayIndex = this.state.allRoles.findIndex(r => r.id === role.id);
                    currentRoles[roleArrayIndex] = changeRole;
                    this.setState({ allRoles: currentRoles, currentRole: changeRole });
                    this.closeModal();
                })
                .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to update role.", messageLabel: "Role Save Error" }));
		} else { // new role
			delete changeRole.id; // guids dont allow an empty string

            let rolesurl = sessionStorage.getItem("apiserver") + "/admin/api/roles/";

            fetch(rolesurl, {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }),
                body: JSON.stringify(changeRole)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.indexOf("error") > -1) {
                        if (data.indexOf("duplicate insert") > -1)
                            this.setState({ showMessageModal: true, messageMessage: "Role already exists. Please add roles with uniques names.", messageLabel: "New Role Duplicate" });
                        else
                            this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add role.", messageLabel: "Problem Adding New Role" });
                    } else {
                        changeRole.id = parseInt(data);
                        role.id = parseInt(data);
                        this.upsertRoleUsers(role.id);
                        this.upsertRoleGroups(role.id);

                        currentRoles = this.state.allRoles;
                        currentDisplayRoles = this.state.displayRoles;

                        currentRoles.splice(this.getInsertionPoint('all', changeRole), 0, changeRole);
                        currentDisplayRoles.splice(this.getInsertionPoint('display', changeRole), 0, changeRole);

                        this.setState({ allRoles: currentRoles, displayRoles: currentDisplayRoles, currentRole: changeRole });
                        this.closeModal();
                        this.setState({ showMessageModal: true, messageMessage: "Role added.", messageLabel: "New Role Added" });
                    }
                })
                .catch(error => {
                    console.log("Error adding role: " + error);
                    this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add role.", messageLabel: "Problem Adding New Role" });
                });
        }
    }

    upsertRoleUsers(roleId) {
        let roleUsers = [];
        for (const user of this.state.allUsers) {
            let roleUserFound = false;
            if (this.state.currentRole.users.some(u => u.id === user.id)) {
                roleUserFound = true;
            }
            roleUsers.push({ roleId: roleId, userId: user.id, addUserRole: roleUserFound });
        }

        let roleUsersInsertUrl = sessionStorage.getItem("apiserver") + "/admin/api/userroles/batchupsert/";
        fetch(roleUsersInsertUrl, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }),
            body: JSON.stringify(roleUsers)
        })
            .then(response => response.json())
            .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add users for this role.", messageLabel: "Error Adding Users To Role" }));
    }

    upsertRoleGroups(roleId) {
        let roleGroups = [];
        for (const group of this.state.allGroups) {
            let groupRoleFound = false;
            if (this.state.currentRole.groups.some(g => g.id === group.id)) {
                groupRoleFound = true;
            }
            roleGroups.push({ groupId: group.id, roleId: roleId, addGroupRole: groupRoleFound });
        }

        let groupRolesInsertUrl = sessionStorage.getItem("apiserver") + "/admin/api/grouproles/batchupsert/";
        fetch(groupRolesInsertUrl, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }),
            body: JSON.stringify(roleGroups)
        })
            .then(response => response.json())
            .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add groups for this role.", messageLabel: "Error Adding Groups To Role" }));
    }

    getInsertionPoint(roleDisplayType, roleToAdd) {
        let retIndex = 0;
        let roleArray = [];

        if (roleDisplayType === "display") {
            roleArray = this.state.displayRoles;
        } else if (roleDisplayType === "all") {
            roleArray = this.state.allRoles;
        }

        for (const role of roleArray) {
            if (role.name > roleToAdd.name) {
                break;
            }
            retIndex++;
        }

        return retIndex;
    }

    handleUserCheckboxChange = (e) => {
        let currentRole = this.state.currentRole;
        if (e.target.checked) {
            currentRole.users.push({ roleId: this.state.currentRole.id, id: e.target.value });
        } else {
            currentRole.users = currentRole.users.filter(u => { return (u.id !== e.target.value) });
        }
        this.setState({ currentRole: currentRole });
    }

    handleGroupCheckboxChange = (e) => {
        let currentRole = this.state.currentRole;
        if (e.target.checked) {
            currentRole.groups.push({ roleId: this.state.currentRole.id, id: Number(e.target.value) });
        } else {
            currentRole.groups = currentRole.groups.filter(g => { return (g.id !== parseInt(e.target.value, 10)) });
        }
        this.setState({ currentRole: currentRole });
    }

    handleShowDeletedCheckboxChange = (e) => {
        this.setState({ showDeletedRoles: e.target.checked })
    }

    handleModalDeleteCheckboxChange = (e) => {
        var currentRole = this.state.currentRole;
        currentRole.deleteFlag = e.target.checked;
        this.setState({ currentRole: currentRole });
    }

    renderRolesTable(roles) {
        let leftDivCounter = 0;
        let rightDivCounter = 0;

        let adminRoles = sessionStorage.getItem("roles").toLowerCase().split(",");
        let type = this.state.type === "all" ? "" : this.state.type + " ";

        let usersMenu = "";
        if (adminRoles.indexOf("admin") > -1 || adminRoles.indexOf("powercal support administrator") > -1 || adminRoles.indexOf("cmr support administrator") > -1) {
            usersMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to="/users">{type}Users</NavLink></NavItem>;
        }

        let groupsMenu = "";
        let rolesMenu = "";
        if (adminRoles.indexOf("admin") > -1) {
            groupsMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to={{ pathname: '/groups', toProps: type }}>{type}Groups</NavLink></NavItem>;
            rolesMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to={{ pathname: '/roles', toProps: type }}>{type}Roles</NavLink></NavItem>;
        }

        return (
            <div>
                <div id="sidebar">
                    <ul className="list-unstyled components">
                        {usersMenu}
                        {groupsMenu}
                        {rolesMenu}
                    </ul>
                </div>
                <table id="dataTable" className="table table-striped">
                    <thead>
                        <tr className="header">
                            <th hidden></th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody id="dataTableBody">
                        {roles.map(role =>
                            <tr className='trRole' key={role.id}>
                                <td className={role.deleteFlag ? 'tdRoleName deletedRole' : 'tdRoleName'} onClick={() => this.openModal(role)}>{role.name}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Modal isOpen={this.state.showModal} style={customStyles} contentLabel="Role" >
                    <div className="modal-title">&nbsp;</div>
                    <div className="row py-4 border-bottom">
                        <div className="col-12 px-4">
                            <div hidden id="popupRoleId"></div>
                            <div className="row">
                                <div className="col-2"><label htmlFor="popupRoleName" className="col-6 font-weight-bold">Role: </label></div>
                                <div className="col-8"><input type="text" id="popupRoleName" onChange={this.onRoleNameChange} value={this.state.currentRole != null ? this.state.currentRole.name : "" } /></div>
                                <div className="col-2 mt-1">
                                    <Checkbox
                                        isSelected={this.state.currentRole.deleteFlag}
                                        label="Delete?"
                                        disabled={this.state.addEditRoleButtonLabel.toLowerCase() === "add role" ? true : false}
                                        onCheckboxChanged={this.handleModalDeleteCheckboxChange}
                                        value={this.state.currentRole.id}
                                        checkboxClasses="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-4 py-4 border-bottom">
                        <div className="col-12" id="divRoleUsers">
                            <div className="row">
                                <div className="col-12 mb-3 font-weight-bold"><u>Users</u></div>
                            </div>
                            <div className="row header">
                                <div className="col-1 chkRoleUserDiv"></div>
                                <div className="col-4">Username</div>
                                <div className="col-4">Email</div>
                            </div>
                            <div className="usersBody">
                                {this.state.allUsers.map((user) => { 
                                    if (!user.deleteFlag) {
                                        return (<div key={user.userId} className='row dataRow rowGroupUser'>
                                            <div className='col-1 text-right'>
                                                <Checkbox
                                                    label=""
                                                    isSelected={this.state.currentRole.users.some(u => u.id === user.id)}
                                                    onCheckboxChanged={this.handleUserCheckboxChange}
                                                    value={user.id}
                                                    checkboxClasses="form-check-input role-user-checkbox"
                                                    key={user.userId} />
                                            </div>
                                            <div hidden className='divRoleUserId'>{user.id}</div>
                                            <div className='col-4 pt-1'>{user.userName}</div>
                                            <div className='col-4 pt-1'>{user.email}</div>
                                        </div>);
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row mx-4 py-4">
                        <div className="col-12" id="divRoleGroups">
                            <div className="row">
                                <div className="col-12 pb-3 font-weight-bold" id="divRoleGroupPopUp"><u>Groups</u></div>
                                <div className="rolesBody col-12 overflow-auto">
                                    <div className="row">
                                        <div className="col-6 ml-3 leftRoleGroupDiv">
                                            {this.state.allGroups.map(group => {
                                                if (!group.deleteFlag) {
                                                    leftDivCounter++;
                                                    if (leftDivCounter === 1 || leftDivCounter % 2 === 1) {
                                                        return (
                                                            <Checkbox
                                                                label={group.name}
                                                                isSelected={this.state.currentRole.groups != null ? this.state.currentRole.groups.some(g => parseInt(g.id, 10) === group.id) : false}
                                                                onCheckboxChanged={this.handleGroupCheckboxChange}
                                                                value={group.id}
                                                                checkboxClasses="form-check-input group-role-checkbox"
                                                                key={group.id} />
                                                        );
                                                    } else {
                                                        return ("");
                                                    }
                                                }
                                            })}
                                        </div>
                                        <div className="col-5 rightRoleGroupDiv">
                                            {this.state.allGroups.map(group => {
                                                if (!group.deleteFlag) {
                                                    rightDivCounter++;
                                                    if (rightDivCounter % 2 === 0) {
                                                        return (
                                                            <Checkbox
                                                                label={group.name}
                                                                isSelected={this.state.currentRole.groups != null ? this.state.currentRole.groups.some(g => parseInt(g.id, 10) === group.id) : false}
                                                                onCheckboxChanged={this.handleGroupCheckboxChange}
                                                                value={group.id}
                                                                checkboxClasses="form-check-input group-role-checkbox"
                                                                key={group.id} />
                                                        );
                                                    } else {
                                                        return ("");
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="button-div"><button onClick={() => this.updateRole(this.state.currentRole)}>{this.state.addEditRoleButtonLabel}</button></div>
                        <div className="button-div"><button onClick={this.closeModal}>Cancel</button></div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showMessageModal} style={customMessageStyles} contentLabel={this.state.messageLabel} >
                    <div>
                        <div className="row">
                            <div className="col-12 text-center mt-2 mb-3 font-weight-bold">
                                {this.state.messageMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 button-div text-center"><button onClick={this.closeMessageModal}>Ok</button></div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    render() {
        if (sessionStorage.hasOwnProperty("roles")) {
            let adminRoles = sessionStorage.getItem("roles").toLowerCase().split(",");
            if (adminRoles.indexOf("admin") > -1) {
                let radioButtonGroup = "";
                
                radioButtonGroup = <div className="col-5 mt-1">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="radPowerCal"
                                                value="PowerCal"
                                                onChange={() => this.onAdminTypeChange("PowerCal")}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">PowerCal</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="radCMR"
                                                value="CMR"
                                                onChange={() => this.onAdminTypeChange("CMR")}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio2">CMR</label>
                                        </div>
                                    </div>;

                let contents = this.state.showDeletedRoles ? this.renderRolesTable(this.state.allRoles) : this.renderRolesTable(this.state.displayRoles);
                let type = this.state.type === 'all' ? "" : this.state.type;

                return (
                    <div>
                        <div className="row">
                            <div className="col-3"><h4>{type} Roles</h4></div>
                            {radioButtonGroup}
                            < div className="col-2 mt-1">
                                <Checkbox
                                    isSelected={this.state.showDeletedRoles}
                                    label="Show Deleted Roles"
                                    onCheckboxChanged={this.handleShowDeletedCheckboxChange}
                                    value={this.state.showDeletedRoles}
                                    checkboxClasses="form-check-input" />
                            </div>
                            <div className="addNewUser col-2 mt-1 text-right" onClick={() => this.openModal()}>+ Add New Role</div>
                        </div>
                        {contents}
                    </div>
                );
            } else {
                alert("You are not authorized to view this page.");
                return (<Redirect to="/login?r=users" />);
            }
        } else {
            alert("You are not authorized to view this page.");
            return (<Redirect to="/login?r=users" />);
        }
    }
}

export default Roles;
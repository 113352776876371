import React, { Component } from "react";

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = ({ isSelected: props.isSelected });
    }

    onCheckboxChanged = (e) => {
        this.setState({ isSelected: !this.state.isSelected });
        this.props.onCheckboxChanged(e);
    }

    render() {
        return (
            <div className="my-auto">
                <label className="user-checkbox-label">
                    <input type="checkbox" name={this.props.label} disabled={this.props.disabled} checked={this.state.isSelected} onChange={this.onCheckboxChanged} value={this.props.value} className={this.props.checkboxClasses} />
                    {this.props.label}
                </label>
            </div >
        );
    }
}

export default Checkbox;
import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    UNSAFE_componentWillMount() {
        if (sessionStorage.getItem("apiserver") === null) {
            fetch('getconfig', {
                method: 'GET'
            })
            .then(response => response.json())
            .then(data => {
                sessionStorage.setItem("apiserver", data.apiServer);
            })
            .catch(error => alert("Error occurred while obtaining configuration information."));
        }
    }

  render () {
    return (
      <div>
            <h1>PowerCal Landing Page</h1>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { AuthContext } from "./Auth";


const liStyle = {
    display: 'none'
};

class Login extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = ({
            loading: true,
            username: '',
            password: '',
            isError: false,
            loggedIn: props.loggedIn,
            referrer: ''
        });
    }

    UNSAFE_componentWillMount = () => {
        this.setState({ referrer: window.location.referrer });

        //We might be here from a password reset, which didn't session the api server address
        if (sessionStorage.getItem("apiserver") === null) {
            fetch('getconfig', {
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    sessionStorage.setItem("apiserver", data.apiServer);
                })
                .catch(error => alert("Error occurred while obtaining configuration information."));
        }
    }

    handleLogin = (authContext) => {
        let formData = new URLSearchParams();
        let loggedIn = false;

        formData.append("username", this.state.username);
        formData.append("password", this.state.password);

        // Obtain JWT and place in local storage.
        // Note that only an admin("admin") should be able to surf around this
        // site.  If the authorization level isn't there, always redirect to the home component.
        fetch("loginuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    loggedIn = true;
                    this.setState({ loggedIn: loggedIn });
                }
                return (response.json());
            })
            .then((adminVm) => {
                if (adminVm) {
                    sessionStorage.setItem("roles", adminVm.roles);
                    sessionStorage.setItem("token", adminVm.jwt);
                    if (loggedIn) {
                        if (window.location.search) {
                            let destination = window.location.search.substr(window.location.search.indexOf("r=") + 2);
                            window.location.replace("/" + destination);
                        } else {
                            window.location.replace('/');
                        }
                    }
                }
            })
            .catch((data) => {
                alert("Error occurred while attempting to login user.");
                this.setState({ isError: true, loggedIn: loggedIn });
            });
    }

    handleUsernameChange = (e) => {
        var username = this.state.username;
        username = e.target.value;
        this.setState({ username: username });
    }

    handlePasswordChange = (e) => {
        var password = this.state.password;
        password = e.target.value;
        this.setState({ password: password });
    }

    handleEnterKey = (e) => {
        if (e.key === "Enter") {
            this.handleLogin();
        }
    }

    render() {
        return (
            <AuthContext.Consumer>
                {(context) => {
                    return (<main role="main" className="pb-3">
                                <h1>Log in</h1>
                                <div className="row">
                                    <div className="col-md-4">
                                        <section>
                                                <h4>Use a local account to log in.</h4>
                                                <hr/>
                                                <div className="text-danger validation-summary-valid" data-valmsg-summary="true">
                                                    <ul>
                                                        <li style={liStyle}></li>
                                                    </ul>
                                                </div>
                                                <div className="form-group">
                                                        <label htmlFor="Input_Username">Username</label>
                                                        <input className="form-control" type="text" onChange={this.handleUsernameChange} data-val="true" data-val-required="The Username field is required." id="Input_Username" name="Input.Username" />
                                                        <span className="text-danger field-validation-valid" data-valmsg-for="Input.Username" data-valmsg-replace="true"></span>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Input_Password">Password</label>
                                                        <input className="form-control" type="password" onChange={this.handlePasswordChange} onKeyPress={this.handleEnterKey} data-val="true" data-val-required="The Password field is required." id="Input_Password" name="Input.Password" />
                                                        <span className="text-danger field-validation-valid" data-valmsg-for="Input.Password" data-valmsg-replace="true"></span>
                                                </div>
                                                <div className="form-group">
                                                    <div className="checkbox">
                                                        <label htmlFor="Input_RememberMe">
                                                            <input type="checkbox" data-val="true" data-val-required="The Remember me? field is required." id="Input_RememberMe" name="Input.RememberMe" value="true" />
                                                                &nbsp;&nbsp;Remember me?
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                        <button type="button" onClick={() => this.handleLogin(context)} className="btn btn-primary">Log in</button>
                                                </div>
                                                <div className="form-group">
                                                    <p>
                                                            <a id="forgot-password" href="/forgotPassword2">Forgot your password?</a>
                                                    </p>
                                                    <p>
                                                            <a href="/register">Register as a new user</a>
                                                    </p>
                                                </div>
                                                    <input name="__RequestVerificationToken" type="hidden" value="CfDJ8OIb7bvZe65Io6XjFvEsOdsJYuCrkgwnCMtt2oGi6Zho2jeiGsZD15Yh04CYcmeU5v0UiJrnt4_MhMRqio55_3OM3t4OFc4br1nF_73IPIl47d6H0IncpgYuOXsav0mbMVHJeKKkLFSP0bxz0NfhL9I" />
                                                    <input name="Input.RememberMe" type="hidden" value="false" />
                                        </section>
                                    </div>
                                </div>
                            </main>);
                }}
            </AuthContext.Consumer>
        );
    }
}

export default Login;
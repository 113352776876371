import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import Checkbox from './Checkbox';
import { AuthContext } from './Auth';
import '../css/site.css';

let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '55%',
        fontSize: '.8rem'
    }
};

const customMessageStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        fontSize: '.8rem',
        borderWidth: 'thick'
    }
};

class Groups extends Component {
    static displayName = Groups.name;

    constructor(props) {
        super(props);
        let currentGroup = { id: 0, name: "",  users: [], roles: [] };
        this.state = {
            userRoles: "",
            showModal: false,
            currentGroup: currentGroup,
            allUsers: [], 
            allRoles: [], 
            allGroups: [],
            displayGroups: [],
            loading: true,
            type: props.location.toProps,
            addEditGroupButtonLabel: "Update Group",
            showDeletedGroups: false,
            showMessageModal: false,
            messageMessage: "",
            messageLabel: ""
        };
    }

    UNSAFE_componentWillMount() {
        let roles = sessionStorage.getItem("roles").toLowerCase().split(",");
        if ((roles !== null && roles !== undefined)) {
            if (roles.indexOf("admin") > -1) {
                this.setState({ type: "all", displayGroupTypeCbs: true });
            } else {
                let type = "";
                if (roles.indexOf("powercal support administrator") > -1 && roles.indexOf("cmr support administrator") === -1) {
                    type = "PowerCal";
                    this.setState({ type: type });
                } else if (roles.indexOf("cmr support administrator") > -1 && roles.indexOf("powercal support administrator") === -1) {
                    type = "CMR";
                    this.setState({ type: type });
                }
                this.fetchStuff(type);
            }
        }
    }

    fetchStuff = (type) => {
        let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));
        let groupsurl = sessionStorage.getItem("apiserver") + "/admin/api/Groups";

        let applicationType = "";
        //let adminRoles = sessionStorage.getItem("roles");
        //if (adminRoles.toLowerCase().includes("admin") && adminRoles.toLowerCase().includes("cmr administrator"))
        //    applicationType = "both";
        //else
        //    applicationType = adminRoles.toLowerCase().split(",")[0];

        fetch(groupsurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                let applicationTypeGroups = [];
                if (applicationType === "both") {
                    applicationTypeGroups = data;
                } else {
                    applicationTypeGroups = data.filter((group) => {
                        let found = false;
                        group.applicationTypes.forEach((appType, i) => {
                            if (appType.toLowerCase() === type.toLowerCase()) {
                                found = true;
                                return false; //break
                            }
                        });
                        return found;
                    });
                }
                let filteredGroups = applicationTypeGroups.filter(group => !group.deleteFlag);
                this.setState({ allGroups: data, displayGroups: filteredGroups, loading: false });
            });

        let usersurl = sessionStorage.getItem("apiserver") + "/admin/api/Users?applicationType=" + type;
        fetch(usersurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ allUsers: data });
            });

        let rolesurl = sessionStorage.getItem("apiserver") + "/admin/api/Roles";
        fetch(rolesurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ allRoles: data });
            });
    }

    componentDidMount = () => {
        Modal.setAppElement('body');
    }

    static group = {
        groupId: 0,
        groupName: ""
    }

    // The state.currentGroup.users and .roles collections are being wiped upon redisplay of the same modal.
    // Perform fresh fetch, which is probably the best way to go at any rate so as to 
    // pull in any potential updates made in other admin sessions.
    openModal = (group) => {
        if (group) {
            this.setState({ addEditGroupButtonLabel: "Update Group", currentGroup: group, loading: false, showModal: true });
        } else if (this.state.type !== 'all')  {
            let group = {
                id: 0,
                name: "",
                users: [],
                roles: [],
                deleteFlag: false
            };
            this.setState({ addEditGroupButtonLabel: "Add Group", currentGroup: group, newGroup: true, showModal: true });
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    closeMessageModal = () => {
        this.setState({ showMessageModal: false });
    }

    onGroupNameChange = (e) => {
        var currentGroup = this.state.currentGroup;
        currentGroup.name = e.target.value;
        this.setState({ currentGroup: currentGroup });
    }

    onDeletedChange = (e) => {
        var currentGroup = this.state.currentGroup;
        currentGroup.deleteFlag = e.target.checked;
        this.setState({ currentGroup: currentGroup });
    }

    onAdminTypeChange = (adminType) => {
        this.setState({ type: adminType });
        this.fetchStuff(adminType);
    }

    updateGroup = (group) => {
        let applicationTypes = [];
        applicationTypes.push(this.state.type);

        let changeGroup = {
            id: group.id,
            name: group.name,
            deleteFlag: group.deleteFlag,
            users: group.users,
            roles: group.roles,
            applicationTypes: applicationTypes
        };
        let groupArrayIndex = 0;
        let currentGroups = [];
        let currentDisplayGroups = [];

        if (group.id === 0) {
            let groupsurl = sessionStorage.getItem("apiserver") + "/admin/api/groups/";

            fetch(groupsurl, {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }),
                body: JSON.stringify(changeGroup)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.indexOf("error") > -1) {
                        if (data.indexOf("duplicate insert") > -1)
                            this.setState({ showMessageModal: true, messageMessage: "Group already exists. Please add groups with uniques names.", messageLabel: "New Group Duplicate" });
                        else
                            this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add group.", messageLabel: "Problem Adding New Group" });
                    } else {
                        changeGroup.id = parseInt(data);
                        group.id = parseInt(data);
                        this.upsertGroupUsers(group.id);
                        this.upsertGroupRoles(group.id);

                        currentGroups = this.state.allGroups;
                        currentDisplayGroups = this.state.displayGroups;

                        currentGroups.splice(this.getInsertionPoint('all', changeGroup), 0, changeGroup);
                        currentDisplayGroups.splice(this.getInsertionPoint('display', changeGroup), 0, changeGroup);

                        this.setState({ allGroups: currentGroups, displayGroups: currentDisplayGroups, currentGroup: changeGroup });
                        this.closeModal();
                        this.setState({ showMessageModal: true, messageMessage: "Group added.", messageLabel: "New Group Added" });
                    }
                })
                .catch(error => {
                    this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add group.", messageLabel: "Problem Adding New Group" });
                });
        } else {
            let groupsurl = sessionStorage.getItem("apiserver") + "/admin/api/groups/";
            fetch(groupsurl + this.state.currentGroup.id, {
                method: 'PUT',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }),
                body: JSON.stringify(changeGroup)
            })
                .then(() => {
                    this.upsertGroupUsers(changeGroup.id);
                    this.upsertGroupRoles(changeGroup.id);
                })
                .then(data => {
                    if (changeGroup.deleteFlag && !this.state.showDeletedGroups) {
                        //Remove from display if the delete chackbox was checked
                        currentGroups = [...this.state.displayGroups];
                        groupArrayIndex = this.state.displayGroups.findIndex(g => g.id === group.id);
                        currentGroups.splice(groupArrayIndex, 1);
                    } else if (changeGroup.deleteFlag && this.state.showDeletedGroups) {
                        //This group already had delete checked, but the admin updated something else about it

                    } else {
                        currentGroups = [...this.state.displayGroups];
                        groupArrayIndex = this.state.displayGroups.findIndex(g => g.id === group.id);

                        //This group may have had its delete flag unchecked, which means that it wouldn't have been available
                        //in displayGroups to be found.  Add it to that array in that case.
                        if (groupArrayIndex === -1)
                            currentGroups.splice(this.getInsertionPoint('display', changeGroup), 0, changeGroup);
                        else
                            currentGroups[groupArrayIndex] = changeGroup;
                    }
                    this.setState({ displayGroups: currentGroups });

                    currentGroups = [...this.state.allGroups];
                    groupArrayIndex = this.state.allGroups.findIndex(g => g.id === group.id);
                    currentGroups[groupArrayIndex] = changeGroup;
                    this.setState({ allGroups: currentGroups, currentGroup: changeGroup });
                    this.closeModal();
                })
                .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to update group.", messageLabel: "Group Save Error" }));
        }
    }

    upsertGroupUsers(groupId) {
        let groupUsers = [];
        for (const user of this.state.allUsers) {
            let groupUserFound = false;
            if (this.state.currentGroup.users.some(u => u.id === user.id)) {
                groupUserFound = true;
            }
            groupUsers.push({ groupId: groupId, userId: user.id, addUserGroup: groupUserFound });
        }

        let groupUsersInsertUrl = sessionStorage.getItem("apiserver") + "/admin/api/usergroups/batchupsert/";
        fetch(groupUsersInsertUrl, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }),
            body: JSON.stringify(groupUsers)
        })
            .then(response => response.json())
            .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add users for this group.", messageLabel: "Error Adding Users To Group" }));
    }

    upsertGroupRoles(groupId) {
        let groupRoles = [];
        for (const role of this.state.allRoles) {
            let groupRoleFound = false;
            if (this.state.currentGroup.roles.some(r => r.id === role.id)) {
                groupRoleFound = true;
            }
            groupRoles.push({ groupId: groupId, roleId: role.id, addGroupRole: groupRoleFound });
        }

        let groupRolesInsertUrl = sessionStorage.getItem("apiserver") + "/admin/api/grouproles/batchupsert/";
        fetch(groupRolesInsertUrl, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }),
            body: JSON.stringify(groupRoles)
        })
            .then(response => response.json())
            .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add roles for this group.", messageLabel: "Error Adding Roles To Group" }));
    }

    getInsertionPoint(groupDisplayType, groupToAdd) {
        let retIndex = 0;
        let groupArray = [];

        if (groupDisplayType === "display") {
            groupArray = this.state.displayGroups;
        } else if (groupDisplayType === "all") {
            groupArray = this.state.allGroups;
        }

        for (const group of groupArray) {
            if (group.name > groupToAdd.name) {
                break;
            }
            retIndex++;
        }

        return retIndex;
    }

    handleUserCheckboxChange = (e) => {
        let currentGroup = this.state.currentGroup;
        if (e.target.checked) {
            currentGroup.users.push({ groupId: this.state.currentGroup.id, id: e.target.value });
        } else {
            currentGroup.users = currentGroup.users.filter(u => { return(u.id !== e.target.value) });
        }
        this.setState({ currentGroup: currentGroup });
    }

    handleRoleCheckboxChange = (e) => {
        let currentGroup = this.state.currentGroup;
        if (e.target.checked) {
            currentGroup.roles.push({ groupId: this.state.currentGroup.id, id: e.target.value });
        } else {
            console.log("e.target.value: " + e.target.value);
            currentGroup.roles = currentGroup.roles.filter(r => { return(r.id !== e.target.value) });
        }
        this.setState({ currentGroup: currentGroup });
    }

    handleShowDeletedCheckboxChange = (e) => {
        this.setState({ showDeletedGroups: e.target.checked })
    }

    renderGroupsTable(groups) {
        let leftDivCounter = 0;
        let rightDivCounter = 0;

        let usersMenu = "";
        let type = this.state.type === "all" ? "" : this.state.type + " ";

        let roles = sessionStorage.getItem("roles").toLowerCase().split(",");
        if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1 || roles.indexOf("cmr support administrator") > -1) {
            usersMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to="/users">{type}Users</NavLink></NavItem>;
        }

        let groupsMenu = "";
        if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1) {
            groupsMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to={{ pathname: '/groups', toProps: type }}>{type}Groups</NavLink></NavItem>;
        }

        let rolesMenu = "";
        if (roles.indexOf("admin") > -1) {
            rolesMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to={{ pathname: '/roles', toProps: type }}>{type}Roles</NavLink></NavItem>;
        }

        return (
            <div className="divRenderGroupsTable">
                <div id="sidebar">
                    <ul className="list-unstyled components">
                        {usersMenu}
                        {groupsMenu}
                        {rolesMenu}
                    </ul>
                </div>
                <table id="dataTable" className="table table-striped">
                    <thead className="groupHeader">
                        <tr className="header">
                            <th hidden></th>
                            <th>Group</th>
                        </tr>
                    </thead>
                    <tbody id="dataTableBody">
                        {groups.map(group =>
                            <tr key={group.id}>
                                <td hidden className='tdGroupId'>{group.id}</td>
                                <td className={group.deleteFlag ? 'tdGroupName deletedGroup' : 'tdGroupName'} onClick={() => this.openModal(group)}>{group.name}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
                <Modal isOpen={this.state.showModal} style={ customStyles } contentLabel="Group">
                    <div className="modal-title">&nbsp;</div>
                    <div className="row py-4 border-bottom">
                        <div className="col-6 px-4">
                            <div hidden id="popupGroupId"></div>
                            <div className="row">
                                <div className="col-2"><label htmlFor="popupGroupName" className="col-6 font-weight-bold">Group: </label></div>
                                <div className="col-3"><input type="text" id="popupGroupName" onChange={this.onGroupNameChange} value={this.state.currentGroup ? this.state.currentGroup.name : ""} /></div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="col-6 pl-6">
                                <Checkbox
                                    isSelected={this.state.currentGroup.deleteFlag}
                                    label="Delete?"
                                    disabled={this.state.addEditGroupButtonLabel.toLowerCase() === "add group" ? true : false }
                                    onCheckboxChanged={this.onDeletedChange}
                                    value={this.state.currentGroup.id}
                                    checkboxClasses="form-check-input" />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-4 py-4 border-bottom">
                        <div className="col-12" id="divGroupUsers">
                            <div className="row">
                                <div className="col-12 mb-3 font-weight-bold"><u>Users</u></div>
                            </div>
                            <div className="row header">
                                <div hidden className="groupUserId"></div>
                                <div className="col-1 chkGroupUserDiv"></div>
                                <div className="col-4">Username</div>
                                <div className="col-4">Email</div>
                            </div>
                            <div className="usersBody overflow-auto">
                                {this.state.allUsers.map((user) => {  // Need to replace this with <Table />. Right now, <Table> is not responding to max height/overflow-y and users are overflowing the screen.
                                    if (!user.deleteFlag) {
                                        return (<div key={user.id} className='row dataRow rowGroupUser'>
                                            <div className='col-1 text-right'>
                                                <Checkbox
                                                    label=""
                                                    isSelected={this.state.currentGroup.users.some(u => u.id === user.id)}
                                                    onCheckboxChanged={this.handleUserCheckboxChange}
                                                    value={user.id}
                                                    checkboxClasses="form-check-input"
                                                    key={user.id} />
                                            </div>
                                            <div className='col-4 pt-1'>{user.userName}</div>
                                            <div className='col-4 pt-1'>{user.email}</div>
                                        </div>);
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row mx-4 py-4">
                        <div className="col-12" id="divGroupRoles">
                            <div className="row">
                                <div className="col-12 pb-3 font-weight-bold" id="divGroupRolePopUp"><u>Roles</u></div>
                                <div className="rolesBody col-12 overflow-auto">
                                    <div className="row">
                                        <div className="col-6 leftGroupRoleDiv ml-3">
                                            {this.state.allRoles.map(role => {  // Need to replace this with <Table responsive />
                                                if (!role.deleteFlag) {
                                                    leftDivCounter++;
                                                    if (leftDivCounter === 1 || leftDivCounter % 2 === 1) {
                                                        return (
                                                            <Checkbox
                                                                label={role.name}
                                                                isSelected={this.state.currentGroup.roles != null ? this.state.currentGroup.roles.some(r => r.id === role.id) : false}
                                                                onCheckboxChanged={this.handleRoleCheckboxChange}
                                                                value={role.id}
                                                                checkboxClasses="form-check-input group-role-checkbox"
                                                                key={role.id} />
                                                        );
                                                    } else {
                                                        return ("");
                                                    }
                                                }
                                            })}
                                        </div>
                                        <div className="col-5 rightGroupRoleDiv">
                                            {this.state.allRoles.map(role => {
                                                if (!role.deleteFlag) {
                                                    rightDivCounter++;
                                                    if (rightDivCounter % 2 === 0) {
                                                        return (
                                                            <Checkbox
                                                                label={role.name}
                                                                isSelected={this.state.currentGroup.roles != null ? this.state.currentGroup.roles.some(r => r.id === role.id) : false}
                                                                onCheckboxChanged={this.handleRoleCheckboxChange}
                                                                value={role.id}
                                                                checkboxClasses="form-check-input group-role-checkbox"
                                                                key={role.id} />
                                                        );
                                                    } else {
                                                        return ("");
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="button-div"><button onClick={() => this.updateGroup(this.state.currentGroup)}>{this.state.addEditGroupButtonLabel}</button></div>
                        <div className="button-div"><button onClick={this.closeModal}>Cancel</button></div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showMessageModal} style={customMessageStyles} contentLabel={this.state.messageLabel} >
                    <div>
                        <div className="row">
                            <div className="col-12 text-center mt-2 mb-3 font-weight-bold">
                                {this.state.messageMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 button-div text-center"><button onClick={this.closeMessageModal}>Ok</button></div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    render() {
        let radioButtonGroup = "";
        let roles = [];
        if (sessionStorage.hasOwnProperty("roles")) {
            roles = sessionStorage.getItem("roles").toLowerCase().split(",");
            if (roles.indexOf("admin") > -1) {
                radioButtonGroup = <div className="col-5 mt-1">
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="radPowerCal"
                            value="PowerCal"
                            onChange={() => this.onAdminTypeChange("PowerCal")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">PowerCal</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="radCMR"
                            value="CMR"
                            onChange={() => this.onAdminTypeChange("CMR")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">CMR</label>
                    </div>
                </div>;
            } else {
                radioButtonGroup = <div className="col-5"></div>;
            }

            if (roles.indexOf("admin") > -1) {
                let contents = this.state.showDeletedGroups ? this.renderGroupsTable(this.state.allGroups) : this.renderGroupsTable(this.state.displayGroups);

                return (
                    <AuthContext.Consumer>
                        {(context) => {
                            let type = this.state.type === "all" ? "" : this.state.type + " ";
                            return (
                                <div className="row">
                                    <div className="col-3"><h3>{type}Groups</h3></div>
                                    {radioButtonGroup}
                                    <div className="col-2 mt-1">
                                        <Checkbox
                                            isSelected={this.state.showDeletedGroups}
                                            label="Show Deleted Groups"
                                            onCheckboxChanged={this.handleShowDeletedCheckboxChange}
                                            value={this.state.showDeletedGroups}
                                            checkboxClasses="form-check-input" />
                                    </div>
                                    <div className="addNewUser col-2 mt-1 text-right" onClick={() => this.openModal()}>+ Add New Group</div>
                                    {contents}
                                </div>);
                        }}
                    </AuthContext.Consumer>);
            } else {
                alert("You are not authorized to view this page.");
                return (<Redirect to="/login?r=users" />);
            }
        } else {
            alert("You are not authorized to view this page.");
            return (<Redirect to="/login?r=users" />);
        }
    }
}

export default Groups;
import { createContext } from 'react';

//export const AuthContext = createContext({
//    authToken: null,
//    setAuthToken: () => { }
//});

export const AuthContext = createContext({ state: null });

//export class Auth extends Component {
//    constructor(props) {
//        super(props);

//        this.state = { claims: [] };
//    }

//    setClaims = (claims) => {
//        this.setState({ claims: claims });
//    }
//}
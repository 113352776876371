import React, { Component } from 'react';
import { Form, Col, Row, Button, Alert } from 'react-bootstrap';


class ForgotPassword2 extends Component {
    constructor(props) {
        super(props);
        this.state = { email: '', validated: false, showAlert: false, alertMessage: '', messageType: '' };
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    submitForm = (e) => {
        e.preventDefault();

        this.setState({
            showAlert: false,
            alertMessage: '',
            messageType: ''
        });

        //built in bootstrap form validation
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({ validated: true });
            return;
        }

        fetch('forgotpassword/' + this.state.email, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        email: '', validated: false, showAlert: true,
                        alertMessage: "If an account is associated with this address, you should receive an email shortly.", messageType: 'success'
                    });
                } else {
                    this.setState({
                        showAlert: true,
                        alertMessage: "Problem occurred while attempting to reset your password.  Please contact Powerteq support.",
                        messageType: 'danger'
                    });
                }
            })
            .catch(error => {
                this.setState({
                    showAlert: true,
                    alertMessage: "Error occurred while attempting to reset password: " + error,
                    messageType: 'danger'
                });
            });
    }

    render() {
        return (
            <div className="container">
                <h1>Forgot Password</h1>
                <hr />
                {this.state.showAlert ? <Alerter alertMessage={this.state.alertMessage} variant={this.state.messageType} /> : null}
                <Form noValidate validated={this.state.validated} method="post" onSubmit={this.submitForm}>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Email</Form.Label>
                        <Col sm={4}>
                            <Form.Control onChange={this.onEmailChange} value={this.state.email} required type="text" />
                            <Form.Control.Feedback type="invalid">Please provide an email</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={{ span: 4, offset: 2 }}>
                            <Button type="submit">Forgot password</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

function Alerter(props) {
    return (
        <Alert variant={props.variant}>
            {props.alertMessage}
        </Alert>
    );
}

export default ForgotPassword2;
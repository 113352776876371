import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import Users from './components/Users';
import Groups from './components/Groups';
import Roles from './components/Roles';
import Register from './components/Register';
import SignOut from './components/SignOut';
import { AuthContext } from './components/Auth';
import { BrowserRouter } from 'react-router-dom';
import Login from './components/Login';
import ForgotPassword2 from './components/ForgotPassword2';
import ResetPassword from './components/ResetPassword';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { claims: [] }
        this.setClaims = this.setClaims.bind(this);
    }

    UNSAFE_componentDidMount() {
        this.handleAppClose();
    }

    handleAppClose() {
        window.addEventListener("onbeforeunload", (e) => {
            if (sessionStorage.hasOwnProperty("claims")) {
                sessionStorage.removeItem("claims");
            }
        });
    }

    setToken = (data) => {
        console.log("App.setToken(); data: " + data);
        this.setState({ authToken: data });
    }

    setClaims = (data) => {
        try {
            this.setState({ claims: data });
        } catch (error) {
            console.log("Error: " + error);
        }
    }

    render() {
        return (
            <AuthContext.Provider value={{ state: this.state, setClaims: this.setClaims }}>
                <BrowserRouter basename={baseUrl}>
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route path='/users' component={Users} />
                        <Route path='/groups' component={Groups} />
                        <Route path='/roles' component={Roles} />
                        <Route path='/register' component={Register} />
                        <Route path='/login' component={Login} />
                        <Route path='/signout' component={SignOut} />
                        <Route path='/forgotPassword2' component={ForgotPassword2} />
                        <Route path='/resetpassword' component={ResetPassword} />
                    </Layout>
                </BrowserRouter>
            </AuthContext.Provider>
        );
    }
}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';



const customMessageStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        fontSize: '.8rem',
        borderWidth: 'thick'
    }
};

class SignOut extends Component {
    componentDidMount = () => {
        fetch('signout', {
            method: 'POST'
        })
            .then(response => {
                if (response.ok) {
                    if (sessionStorage.hasOwnProperty("roles")) {
                        let roles = sessionStorage.getItem("roles");
                        if (roles) {
                            sessionStorage.removeItem("roles");
                        }
                    }
                    if (sessionStorage.hasOwnProperty("token")) {
                        let roles = sessionStorage.getItem("token");
                        if (roles) {
                            sessionStorage.removeItem("token");
                        }
                    }
                    alert("You are now signed out of PowerCal.");
                    window.location.reload();
                }
            })
            .catch(error => {
                alert("Error occurred while attempting to sign out of PowerCal.");
            });
    }

    render() {
        return (
            <Redirect to="/" />
        );
    }
}

export default SignOut;
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import Checkbox from './Checkbox';
import { AuthContext } from './Auth';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../css/primereact.min.css';
import '../css/primeicons/primeicons.css';
import '../css/site.css';

let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));

const customUserStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        fontSize: '.8rem'
    }
};

const customMessageStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        fontSize: '.8rem',
        borderWidth: 'thick'
    }
};

class Users extends Component {
    static displayName = Users.name;

    constructor(props) {
        super(props);
        let currentUser = {
            id: "",
            userName: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            fax: "",
            company: "",
            password: "",
            passwordConfirm: "",
            maxUserMachineCount: 2,
            carbCertified: false,
            approved: false,
            active: false,
            groups: [],
            roles: [],
            powerCalUser: false,
            cmrUser: false,
            offlineLoginDays: 7,
            deleteFlag: false
        };

        this.state = {
            enableSelection: true,
            selected: null,
            dataFetched: false,
            showUserModal: false,
            showDeletedUsers: false,
            displayUserTypeCbs: false,
            enablePasswordConfirm: false,
            promiseChainError: false,
            newUser: false,
            password: "",
            passwordConfirm: "",
            displayUsers: [],
            allUsers: [],
            currentUser: currentUser,
            addEditUserButtonLabel: "",
            allGroups: [],
            displayGroups: [],
            allRoles: [],
            displayRoles: [],
            authToken: null,
            type: null,
            currentSearch: "",
            displayUsersLength: 0,
            cmrAdminOnlyUser: false,
            powercalSupportTechOnlyUser: false
        };
    }

    componentDidMount = () => {
        let rolesCheck = sessionStorage.getItem("roles");

        if ((rolesCheck !== null && rolesCheck !== undefined)) {
            let roles = rolesCheck.toLowerCase().split(",");
            if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1 || roles.indexOf("powercal support tech") > -1 || roles.indexOf("cmr support administrator") > -1) {
                //This admin might be able to adminstrate both PowerCal and CMR users. Fetch users based on the PowerCal/CMR radio button choice, otherwise set type to automatically fetch users.
                if (roles.indexOf("admin") > -1) {
                    this.setState({ displayUserTypeCbs: true, type: 'all' });
                } else {
                    let type = "";
                    if (roles.indexOf("powercal support administrator") > -1 && roles.indexOf("cmr support administrator") === -1) {
                        type = "PowerCal";
                        this.setState({ type: type });
                    } else if (roles.indexOf("cmr support administrator") > -1 && roles.indexOf("powercal support administrator") === -1) {
                        type = "CMR";
                        this.setState({ type: type });
                    }

                    this.fetchStuff(type);
                }
            }

            if (roles.indexOf("powercal support tech") > -1 && roles.indexOf("admin") === -1 && roles.indexOf("powercal support administrator") === -1 && roles.indexOf("cmr administrator") === -1) {
                this.setState({ powercalSupportTechOnlyUser: true });
            } else if (roles.indexOf("cmr administrator") > -1 && roles.indexOf("admin") === -1 && roles.indexOf("powercal support administrator") === -1 && roles.indexOf("powercal support tech") === -1) {
                this.setState({ cmrAdminOnlyUser: true });
            }
        }
        Modal.setAppElement('body');
    }

    fetchStuff = (type) => {
        //let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));
        let usersurl = sessionStorage.getItem("apiserver") + "/admin/api/users?applicationType=" + type;
        let roles = sessionStorage.getItem("roles").toLowerCase();

        fetch(usersurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                let filteredUsers = data.filter(user => !user.deleteFlag);
                this.setState({ allUsers: data, displayUsers: filteredUsers });
            })
            .catch(error => {
                this.setState({ showMessageModal: true, messageMessage: "Error occurred while obtaining user list.", messageLabel: "Error Obtaining Users" });
            });

        let groupsurl = sessionStorage.getItem("apiserver") + "/admin/api/groups";
        fetch(groupsurl, {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + jwt })
        })
            .then(response => response.json())
            .then(data => {
                let powercalGroups = [];
                if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1 || roles.indexOf("powercal support tech") > -1) {
                    powercalGroups = data.filter((group) => {
                        let found = false;
                        group.applicationTypes.forEach((appType, i) => {
                            if (appType.toLowerCase() === "powercal") {
                                found = true;
                                return false; //break
                            }
                        });
                        return found;
                    });
                }

                let cmrGroups = [];
                if (roles.indexOf("admin") > -1 || roles.indexOf("cmr administrator") > -1 || roles.indexOf("cmr support administrator") > -1) {
                    cmrGroups = data.filter((group) => {
                        let found = false;
                        group.applicationTypes.forEach((appType, i) => {
                            if (appType.toLowerCase() === "cmr") {
                                found = true;
                                return false; //break
                            }
                        });
                        return found;
                    })
                }

                let displayGroups = cmrGroups.concat(powercalGroups);
                this.setState({ allGroups: data, displayGroups: displayGroups });
            })
            .catch(error => {
                this.setState({ showMessageModal: true, messageMessage: "Error occurred while obtaining groups.", messageLabel: "Error Obtaining Groups" });
            });

        if (!this.state.powercalSupportTechOnlyUser) {
            let rolesurl = sessionStorage.getItem("apiserver") + "/admin/api/roles";
            fetch(rolesurl, {
                method: 'GET',
                headers: new Headers({ Authorization: 'Bearer ' + jwt })
            })
                .then(response => response.json())
                .then(data => {
                    let powercalRoles = [];
                    if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1 || roles.indexOf("cmr support administrator") > -1) {
                        powercalRoles = data.filter((role) => {
                            let found = false;
                            role.applicationTypes.forEach((appType, i) => {
                                if (appType.toLowerCase() === "powercal") {
                                    found = true;
                                    return false; //break
                                }
                            });
                            return found;
                        });
                    }

                    let cmrRoles = [];
                    if (roles.indexOf("cmr support administrator") > -1) {
                        cmrRoles = data.filter((role) => {
                            let found = false;
                            role.applicationTypes.forEach((appType, i) => {
                                if (appType.toLowerCase() === "cmr") {
                                    found = true;
                                    return false; //break
                                }
                            });
                            return found;
                        });
                    }

                    const displayRoles = [...new Set([...cmrRoles, ...powercalRoles])];
                    this.setState({ allRoles: data, displayRoles: displayRoles });
                })
                .catch(error => {
                    this.setState({ showMessageModal: true, messageMessage: "Error occurred while obtaining roles.", messageLabel: "Error Obtaining Roles" });
                });
        }

        sessionStorage.setItem("adminType", type);
    }

    openUserModal = (user) => {
        if (user) {
            this.setState({ addEditUserButtonLabel: "Update User", currentUser: user, newUser: false, showUserModal: true });
        } else if (this.state.type !== 'all') {
            let user = {
                id: "",
                userName: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                fax: "",
                company: "",
                password: "",
                passwordConfirm: "",
                maxUserMachineCount: 2,
                carbCertified: false,
                approved: false,
                active: false,
                groups: [],
                roles: [],
                powerCalUser: false,
                cmrUser: false,
                offlineLoginDays: 7,
                deleteFlag: false
            };
            this.setState({ addEditUserButtonLabel: "Add User", currentUser: user, newUser: true, showUserModal: true, password: "", passwordConfirm: "" });
        }
    }

    openMessageModal = () => {
        this.setState({ showMessageModal: true });
    }

    closeUserModal = () => {
        this.setState({ showUserModal: false });
    }

    closeMessageModal = () => {
        this.setState({ showMessageModal: false });
    }

    updateUser = (user) => {
        let changeUser = {
            id: user.id, // for Core EF entity
            userName: user.userName,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            fax: user.fax,
            company: user.company,
            password: user.password ? user.password : this.state.password,
            maxUserMachineCount: user.maxUserMachineCount,
            carbCertified: user.carbCertified,
            approved: user.approved,
            active: user.active,
            groups: user.groups,
            roles: user.roles,
            normalizedEmail: user.normalizedEmail,
            passwordHash: user.passwordHash,
            securityStamp: user.securityStamp,
            powerCalUser: user.powerCalUser,
            cmrUser: user.cmrUser,
            offlineLoginDays: user.offlineLoginDays,
            deleteFlag: user.deleteFlag
        };

        this.setState({ promiseChainError: false });

        let userArrayIndex = 0;
        let currentUsers = [];
        let currentDisplayUsers = [];

        if (changeUser.id !== "") { // updating existing user
            let usersurl = sessionStorage.getItem("apiserver") + "/admin/api/users/";
            fetch(usersurl + user.id, {
                method: 'PUT',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }),
                body: JSON.stringify(changeUser)
            })
                .then(response => response.json())
                .then(data => {
                    this.upsertUserRoles(user.id);
                    this.upsertUserGroups(user.id);
                })
                .then(data => {
                    if (changeUser.deleteFlag && !this.state.showDeletedUsers) {
                    //if (changeUser.deleteFlag && this.state.displayUsers.contains(changeUser)) {
                        //if the user was set to deleted and Show Deleted Users is not checked, remove from displayed users
                        currentUsers = [...this.state.displayUsers];
                        userArrayIndex = this.state.displayUsers.findIndex(u => u.id === user.id);
                        currentUsers.splice(userArrayIndex, 1);
                        this.setState({ displayUsers: currentUsers });
                    }
                    //else if (changeUser.deleteFlag && this.state.showDeletedUsers) {
                    //    //This role already had delete checked, but the admin updated something else about it

                    //}
                    else {
                        currentUsers = [...this.state.displayUsers];
                        userArrayIndex = this.state.displayUsers.findIndex(u => u.id === changeUser.id);

                        //This user may have had its delete flag unchecked, which means that it wouldn't have been available
                        //in displayUsers to be found.  Add it to that array in that case.
                        if (userArrayIndex === -1) {
                            currentUsers.splice(this.getInsertionPoint('display', changeUser), 0, changeUser);
                            //currentUsers.splice(currentUsers.findIndex(u => u.userName === changeUser.userName), 0, changeUser);
                        }
                        else if (currentUsers.findIndex(u => u.userName === changeUser.userName) > -1 && changeUser.deleteFlag) {
                            // Current user is in state.displayUsers, which should never contain deleted users
                            //currentUsers.splice(currentUsers.findIndex(u => u.userName === changeUser.userName), 1);
                            currentUsers.splice(this.getInsertionPoint('display', changeUser), 1);
                        }
                        else {
                            currentUsers[userArrayIndex] = changeUser;
                        }

                        this.setState({ displayUsers: currentUsers });
                    }

                    currentUsers = [...this.state.allUsers];
                    userArrayIndex = this.state.allUsers.findIndex(u => u.id === user.id);
                    currentUsers[userArrayIndex] = changeUser;
                    this.setState({ allUsers: currentUsers, currentUser: changeUser });
                    this.closeUserModal();
                })
                .catch(error => {
					this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to update user.", messageLabel: "Error Updating User" });

					// get the user's original values from server
					let userurl = sessionStorage.getItem("apiserver") + "/admin/api/users/getuser/" + user.id;
					fetch(userurl, {
						method: 'GET',
						headers: new Headers({ Authorization: 'Bearer ' + jwt })
					})
						.then(response => response.json())
						.then(data => {
							let tempAllUsers = this.state.allUsers;
							userArrayIndex = this.state.allUsers.findIndex(u => u.id === user.id);
							tempAllUsers[userArrayIndex] = data; // reset allUsers array with original user values

							let tempDisplayUers = this.state.displayUsers;
							userArrayIndex = this.state.displayUsers.findIndex(u => u.id === user.id);
							tempDisplayUers[userArrayIndex] = data; // reset displayUsers array with original user values

							this.setState({ allUsers: tempAllUsers, displayUsers: tempDisplayUers, currentUser: data }); // reset state with original user values
						})
						.catch(err => {
							window.location.reload(false); // something went wrong, just do a hard refresh
						});
                });
		} else { // new user
			delete changeUser.id; // guids dont allow an empty string

            let divMsg = document.getElementById("divMessage");
            if (this.state.password !== this.state.passwordConfirm) {
                divMsg.innerHTML = "Passwords do not match.";
                return;
            } else {
                divMsg.innerHTML = "";
                let usersurl = sessionStorage.getItem("apiserver") + "/admin/api/users/";

                if (this.state.displayUserTypeCbs) {
                    //if (!this.state.cmrUser && !this.state.powerCalUser) {
                    if (!changeUser.cmrUser && !changeUser.powerCalUser) {
                        divMsg.innerHTML = "Please select User Type.";
                        return;
                    }
                } else {
                    let roles = sessionStorage.getItem("roles").toLowerCase().split(",");
                    if (roles.indexOf("admin") === -1) {
                        if (roles.indexOf("powercal support administrator") > -1) {
                            changeUser.powerCalUser = true;
                        } else if (roles.indexOf("cmr support administrator") > -1) {
                            changeUser.cmrUser = true;
                        }
                    }
                }

                fetch(usersurl, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    }),
                    body: JSON.stringify(changeUser)
                })
                    .then(response => {
                        if (response.status !== 200) {
                            if (response.status === 409) {
                                this.setState({ promiseChainError: true, showMessageModal: true, messageMessage: "User already exists. Please add users with uniques usernames.", messageLabel: "New User Duplicate" });
                            }
                            else {
                                this.setState({ promiseChainError: true, showMessageModal: true, messageMessage: "Error occurred while attempting to add user.", messageLabel: "Problem Adding New User" });
                            }
                        }

                        return response;
                    })
                .then(response => { return response.json(); })
                .then(data => {
                    if (!this.state.promiseChainError) {
                        changeUser.id = data;
                        user.id = data;
                        this.upsertUserRoles(user.id);
                        this.upsertUserGroups(user.id);

                        currentUsers = this.state.allUsers;
                        currentDisplayUsers = this.state.displayUsers;
                        currentUsers.splice(this.getInsertionPoint('all', changeUser), 0, changeUser);
                        currentDisplayUsers.splice(this.getInsertionPoint('display', changeUser), 0, changeUser);

                        this.setState({ allUsers: currentUsers, displayUsers: currentDisplayUsers, currentUser: changeUser });
                        this.closeUserModal();
                        this.setState({ showMessageModal: true, messageMessage: "User added.", messageLabel: "New User Added" });
                    }
                })
                .catch(error => {
                    this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add user.", messageLabel: "Error Adding User" });
                });
            }
        }
    }

    getInsertionPoint(userDisplayType, userToAdd) {
        let userArray = this.state.displayUsers;
        let startIndex = 0;
        let stopIndex = userArray.length - 1;
        let middle = Math.floor((stopIndex + startIndex) / 2);

        while (userArray[middle].userName != userToAdd.userName && startIndex < stopIndex) {
            if (userToAdd.userName < userArray[middle].userName) {
                stopIndex = middle - 1;
            } else if (userToAdd.userName > userArray[middle].userName) {
                startIndex = middle + 1;
            }

            middle = Math.floor((stopIndex + startIndex) / 2);
        }

        return middle + 1;
    }

    upsertUserRoles(userId) {
        // Only insert roles directly associated to this user (not via a group)
        let userRoles = [];
        for (const role of this.state.allRoles) {
            let userRoleFound = false;
            if (this.state.currentUser.roles.some(r => (r.roleId === role.id && r.addUserRole))) {
                userRoleFound = true;
            }
            userRoles.push({ userId: userId, roleId: role.id, addUserRole: userRoleFound });
        }

        let userRoleInsertUrl = sessionStorage.getItem("apiserver") + "/admin/api/userroles/batchupsert/";
        fetch(userRoleInsertUrl, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }),
            body: JSON.stringify(userRoles)
        })
            .then(response => response.json())
            .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add roles for this user.", messageLabel: "Error Adding User Roles" }));
    }

    upsertUserGroups(userId) {
        let userGroups = [];
        for (const group of this.state.allGroups) {
            let userGroupFound = false;
            if (this.state.currentUser.groups.some(g => g.id === group.id)) {
                userGroupFound = true;
            }
            userGroups.push({ userId: userId, groupId: group.id, addUserGroup: userGroupFound });
        }

        let userGroupInsertUrl = sessionStorage.getItem("apiserver") + "/admin/api/usergroups/batchupsert/";
        fetch(userGroupInsertUrl, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }),
            body: JSON.stringify(userGroups)
        })
            .then(response => response.json())
            .catch(() => this.setState({ showMessageModal: true, messageMessage: "Error occurred while attempting to add groups for this user.", messageLabel: "Error Adding User Groups" }));
    }

    onUserNameChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.userName = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onFirstNameChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.firstName = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onLastNameChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.lastName = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onEmailChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.email = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onPhoneChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.phoneNumber = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onCompanyChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.company = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onMaxUserMachinesChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.maxUserMachineCount = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onAdminTypeChange = (adminType) => {
        this.setState({ type: adminType });
        this.fetchStuff(adminType);
    }

    onOfflineLoginDaysChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.offlineLoginDays = e.target.value;
        this.setState({ currentUser: currentUser });
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value });
        let img = document.getElementById('imgMatch');
        if (this.state.password !== "") {
            this.setState({ enablePasswordConfirm: true });
            if (this.state.passwordConfirm !== "") {
                img.style.visibility = "visible";
                if (this.state.password === e.target.value) {
                    img.src = 'check.png';
                } else {
                    img.src = 'x.jpg';
                }
            }
        } else {
            if (this.state.passwordConfirm === "")
                img.style.visibility = "hidden";
            this.setState({ enablePasswordConfirm: false });
        }
    }

    onPasswordConfirmChange = (e) => {
        this.setState({ passwordConfirm: e.target.value });
        let img = document.getElementById('imgMatch');
        let divMsg = document.getElementById('divMessage');

        if (e.target.value !== "") {
            img.style.visibility = "visible";
            if (this.state.password === e.target.value) {
                img.src = 'check.png';
            } else {
                img.src = 'x.jpg';
            }
        } else {
            img.style.visibility = "hidden";
        }

        if (divMsg.innerHTML !== '\u00A0')
            divMsg.innerHTML = '\u00A0';
    }

    updateCARB = (user) => {
        if (user) {
            var currentUser = user;
            currentUser.carbCertified = !user.carbCertified;
            this.setState({ currentUser: currentUser });
        }
    }

    updateApproved = (user) => {
        if (user) {
            var currentUser = user;
            currentUser.approved = !user.approved;
            this.setState({ currentUser: currentUser });
        }
    }

    handleModalCARBCertifiedCheckboxChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.carbCertified = e.target.checked;
        this.setState({ currentUser: currentUser });
    }

    handleModalApprovedCheckboxChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.approved = e.target.checked;
        this.setState({ currentUser: currentUser });
    }

    handleModalActiveCheckboxChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.active = e.target.checked;
        this.setState({ currentUser: currentUser });
    }

    handleModalDeleteCheckboxChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.deleteFlag = e.target.checked;
        this.setState({ currentUser: currentUser });
    }

    handleGroupCheckboxChange = (e) => {
        let currentUser = this.state.currentUser;
        //let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));

        if (e.target.checked) {
            currentUser.groups.push({ id: parseInt(e.target.value, 10), userId: currentUser.id });

            // Show user roles related to this group
            let groupRolesUrl = sessionStorage.getItem("apiserver") + "/admin/api/grouproles/GetRolesByGroup?groupId=";
            fetch(groupRolesUrl + e.target.value, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                })
            })
            .then(response => response.json())
            .then(data => {
                for (const groupRole of data) {
                    if (!currentUser.roles.some(ur => ur.roleId === groupRole.roleId)) {
						if (currentUser.id !== "") {
							currentUser.roles.push({ roleId: groupRole.roleId, userId: currentUser.id, addUserRole: false });
						}
						else {
							currentUser.roles.push({ roleId: groupRole.roleId, addUserRole: false });
						}
                    }
                }
                this.setState({ currentUser: currentUser });
            })
                .catch(error => this.setState({ showMessageModal: true, messageMessage: "Error occurred while fetching group roles.", messageLabel: "Error Adding User Groups" }));
        } else {
            currentUser.groups = currentUser.groups.filter(g => g.id !== parseInt(e.target.value, 10));

            // Only remove roles that are related to the unselected group. If there's a direct UserRole 
            // relationship, don't unselect the role.
            let groupRoles = [];
            let userRoles = [];
            let rolesGroupUrl = sessionStorage.getItem("apiserver") + "/admin/api/grouproles/GetRolesByGroup?groupId=";
            fetch(rolesGroupUrl + e.target.value, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                })
            })
                .then(response => response.json())
                .then(data => {
                    groupRoles = data;
                })
                .then(() => {
                    if (groupRoles.length) {
                        let userRolesUrl = sessionStorage.getItem("apiserver") + "/admin/api/userroles/GetRolesByUser?userId=";
                        fetch(userRolesUrl + currentUser.id, {
                            method: 'GET',
                            headers: new Headers({
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + jwt
                            })
                        })
                        .then(response => response.json())
                        .then(data => {
                            userRoles = data;
                        })
                        .then(() => {
                            if (groupRoles.length) {
                                for (const groupRole of groupRoles) {
                                    if (!userRoles.some(ur => ur.id === groupRole.roleId)) {
                                        currentUser.roles = currentUser.roles.filter(r => r.roleId !== groupRole.roleId);
                                    }
                                }
                            }

                        })
                        .then(() => this.setState({ currentUser: currentUser }))
                            .catch(error => this.setState({ showMessageModal: true, messageMessage: "Error occurred while fetching user roles.", messageLabel: "Error Fetching User Roles" }));
                    }
                })
                .then(() => this.setState({ currentUser: currentUser }))
                .catch(error => this.setState({ showMessageModal: true, messageMessage: "Error occurred while fetching group roles.", messageLabel: "Error Fetching Group Roles" }));
        }
    }

    handleShowDeletedCheckboxChange = (e) => {
        this.setState({ showDeletedUsers: e.target.checked })
    }

    handleRoleCheckboxChange = (e) => {
        let currentUser = this.state.currentUser;
        let roleId = e.target.value;
        //let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));

        if (e.target.checked) {
			if (!currentUser.roles.some(r => r.roleId === roleId)) {
				if (currentUser.id !== "") {
					currentUser.roles.push({ roleId: roleId, userId: currentUser.id, addUserRole: true });
				}
				else {
					currentUser.roles.push({ roleId: roleId, addUserRole: true });
				}
					
                this.setState({ currentUser: currentUser });
            }
        } else {
            // Only remove roles that are related directly to the user. If this role
            // is checked because it's related to a selected group, do not allow it
            // to be deselected.
            let groupRoles = [];
            let roleGroupsUrl = sessionStorage.getItem("apiserver") + "/admin/api/grouproles/GetGroupsByRole?roleId=";
            fetch(roleGroupsUrl + roleId, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                })
            })
                .then(response => response.json())
                .then(data => {
                    groupRoles = data;
                })
                .then(() => {
                    let userGroupFound = false;
                    for (const groupRole of groupRoles) {
                        if (currentUser.groups.some(g => g.id === groupRole.groupId)) {
                            userGroupFound = true;
                            break;
                        }
                    }
                    if (userGroupFound) {
                        if (!groupRoles.some(gr => gr.roleId === roleId)) {
                            currentUser.roles = currentUser.roles.filter(r => r.roleId !== roleId);
                            this.setState({ currentUser: currentUser });
                        } else {
                            this.setState({ showMessageModal: true, messageMessage: "The role you're attempting to deselect is associated with a selected group for this user. Deselect the group this role belongs to if you want to remove it for this user.", messageLabel: "Error Deselecting Role" })
                        }
                    } else {
                        currentUser.roles = currentUser.roles.filter(r => r.roleId !== roleId);
                        this.setState({ currentUser: currentUser });
                    }
                })
                .catch(error => this.setState({ showMessageModal: true, messageMessage: "Error occurred while fetching group roles.", messageLabel: "Error Fetching Group Roles" }));
;
        }
    }

    handleResetLocks = (e) => {
        let currentUser = this.state.currentUser;
        //let jwt = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));
        let resetMachinesUrl = sessionStorage.getItem("apiserver") + "/admin/api/users/resetmachines/";
        fetch(resetMachinesUrl + currentUser.id, {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                })
        })
        .then(response => {
            if (response.ok) {
                this.setState({ showMessageModal: true, messageMessage: "Machines reset.", messageLabel: "" });
            } else {
                this.setState({ showMessageModal: true, messageMessage: "Server error occurred while attempting to reset this user's machines.", messageLabel: "Server Error" });
            }
        })
        .catch(error => {
            this.setState({ showMessageModal: true, messageMessage: "Server error occurred while attempting to reset this user's machines.", messageLabel: "Server Error" });
        });
    }

    handlePowerCalUserCheckboxChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.powerCalUser = e.target.checked;
        this.setState({ currentUser: currentUser });
    }

    handleCMRUserCheckboxChange = (e) => {
        var currentUser = this.state.currentUser;
        currentUser.cmrUser = e.target.checked;
        this.setState({ currentUser: currentUser });
    }

    carbCertifiedTemplate = (rowData) => {
        return <div className="headerCheck">{rowData.carbCertified ? <img src='check2.png' width="20" height="20" /> : ""}</div>;
    }

    approvedTemplate = (rowData) => {
        return <div className="headerCheck">{rowData.approved ? <img src='check2.png' width="20" height="20" /> : ""}</div>;
    }

    activeTemplate = (rowData) => {
        return <div className="headerCheck">{rowData.active ? <img src='check2.png' width="20" height="20" /> : ""}</div>;
    }

    rowClassName = (rowData) => {
        return rowData.deleteFlag ? { 'deletedUser': true } : {};
    }

    isValidGroupOrRole = (group) => {
        const isCmrUser = this.state.currentUser.cmrUser;
        const isPowerCalUser = this.state.currentUser.powerCalUser;

        if (isCmrUser && isPowerCalUser)
            return true;

        const roleTypes = new Set();
        group.applicationTypes.forEach(group => roleTypes.add(group));

        if ((isCmrUser && roleTypes.has("CMR")) || (isPowerCalUser && roleTypes.has("PowerCal")))
            return true;

        return false;
    }

    renderUsersTable = (users) => {
        let leftGroupDivCounter = 0;
        let rightGroupDivCounter = 0;
        let leftRoleDivCounter = 0;
        let rightRoleDivCounter = 0;

        let roles = sessionStorage.getItem("roles").toLowerCase().split(",");

        let inputsDisabled = true;
        let groupsDisabled = true;

        let type = this.state.type === "all" ? "" : this.state.type + " ";

        if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1 || roles.indexOf("cmr administrator") > -1) {
            inputsDisabled = false;
        }

        let groupsMenu = "";
        if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1) {
            groupsMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to={{ pathname: '/groups', toProps: type }}>{type}Groups</NavLink></NavItem>;
            groupsDisabled = false;
        }

        let rolesMenu = "";
        if (roles.indexOf("admin") > -1) {
            rolesMenu = <NavItem><NavLink tag={Link} className="sidebar-menu-item" to={{ pathname: '/roles', toProps: type }}>{type}Roles</NavLink></NavItem>;
            groupsDisabled = false
        }

        let appTypeDiv = "";
        let appTypeCheckboxesDiv = "";
        if (this.state.displayUserTypeCbs) {
            appTypeDiv = <div className="row">
                            <div className="col-4"></div>
                            <div className="col-2 divUserTypeTitle">
                                <label>User Type</label>
                            </div>
            </div>

            appTypeCheckboxesDiv = <div className="col-3 border">
                                        <div className="row">
                                            <Checkbox
                                                isSelected={this.state.currentUser.powerCalUser}
                                                label="PowerCal"
                                                onCheckboxChanged={this.handlePowerCalUserCheckboxChange}
                                                value={'PowerCal'}
                                                checkboxClasses="form-check-input" />
                                        </div>
                                        <div className="row">
                                            <Checkbox
                                                isSelected={this.state.currentUser.cmrUser}
                                                label="CMR"
                                                onCheckboxChanged={this.handleCMRUserCheckboxChange}
                                                value={'CMR'}
                                                checkboxClasses="form-check-input" />
                                        </div>
                                    </div>
        }

        let groupsDiv = "";
        let rolesDiv = "";
        let divDelete = "";
        if (!this.state.powercalSupportTechOnlyUser) {
            groupsDiv = <div className="row mx-4 py-2 border-bottom">
                            <div className="col-12" id="divUserGroups">
                                <div className="row">
                                    <div className="col-12"><u><h5>Groups</h5></u></div>
                                    <div className="col-6 leftUserGroupDiv">
                                        {this.state.displayGroups.map(group => {
                                            if (!group.deleteFlag && this.isValidGroupOrRole(group)) {
                                                leftGroupDivCounter++;
                                                if (leftGroupDivCounter === 1 || leftGroupDivCounter % 2 === 1) {
                                                    return (
                                                        <div className="my-auto" key={group.id}>
                                                            <label className="user-checkbox-label">
                                                                <input type="checkbox" checked={this.state.currentUser.groups.some(g => g.id === group.id)} disabled={groupsDisabled} onChange={(e) => { e.data = group; this.handleGroupCheckboxChange(e) }} value={group.id} className="form-check-input user-group-checkbox" />
                                                                {group.name}
                                                            </label>
                                                        </div>
                                                    );
                                                } else {
                                                    return ("");
                                                }
                                            }
                                        })}
                                    </div>
                                    <div className="col-6 rightUserGroupDiv">
                                        {this.state.displayGroups.map(group => {
                                            if (!group.deleteFlag && this.isValidGroupOrRole(group)) {
                                                rightGroupDivCounter++;
                                                if (rightGroupDivCounter % 2 === 0) {
                                                    return (
                                                        <div className="my-auto" key={group.id}>
                                                            <label className="user-checkbox-label">
                                                                <input type="checkbox" checked={this.state.currentUser.groups.some(g => g.id === group.id)} disabled={groupsDisabled} onChange={(e) => { e.data = group; this.handleGroupCheckboxChange(e) }} value={group.id} className="form-check-input user-group-checkbox" />
                                                                {group.name}
                                                            </label>
                                                        </div>
                                                    )
                                                } else {
                                                    return ("");
                                                }
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

            rolesDiv = <div className="row mx-4 py-2">
                            <div className="col-12" id="divUserRoles">
                                <div className="row">
                                    <div className="col-12" id="divUserRolePopUp"><u><h5>Roles</h5></u></div>
                                    <div className="col-6 leftRoleDiv">
                                        {this.state.displayRoles.map(role => {
                                            if (!role.deleteFlag && this.isValidGroupOrRole(role)) {
                                                leftRoleDivCounter++;
                                                if (leftRoleDivCounter === 1 || leftRoleDivCounter % 2 === 1) {
                                                    return (
                                                        <div className="my-auto" key={role.id}>
                                                            <label className="user-checkbox-label">
                                                                <input type="checkbox" checked={this.state.currentUser.roles.some(r => r.roleId === role.id)} disabled={this.state.cmrAdminOnlyUser && role.internal ? true : false}
                                                                    onChange={(e) => { e.data = role; this.handleRoleCheckboxChange(e) }} value={role.id} className="form-check-input user-role-checkbox" />
                                                                {role.name}
                                                            </label>
                                                        </div>
                                                    )
                                                } else {
                                                    return ("");
                                                }
                                            }
                                        })}
                                    </div>
                                    <div className="col-6 rightRoleDiv">
                                        {this.state.displayRoles.map(role => {
                                            if (!role.deleteFlag && this.isValidGroupOrRole(role)) {
                                                rightRoleDivCounter++;
                                                if (rightRoleDivCounter % 2 === 0) {
                                                    return (
                                                        <div className="my-auto" key={role.id}>
                                                            <label className="user-checkbox-label">
                                                                <input type="checkbox" checked={this.state.currentUser.roles.some(r => r.roleId === role.id)} disabled={this.state.cmrAdminOnlyUser && role.internal ? true : false}
                                                                    onChange={(e) => { e.data = role; this.handleRoleCheckboxChange(e) }} value={role.id} className="form-check-input user-role-checkbox" />
                                                                {role.name}
                                                            </label>
                                                        </div>
                                                    )
                                                } else {
                                                    return ("");
                                                }
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
            </div>

			divDelete = <div className="col-6 pl-6">
							<label>
								<input
									type="checkbox"
									checked={this.state.currentUser.deleteFlag}
									onChange={this.handleModalDeleteCheckboxChange}
									value={this.state.currentUser.id}
									className="form-check-input" />
								Delete?
							</label>
                        </div>
        }

        let header = <div className="header">
                        <div className="headerUsername">Username</div>
                        <div className="headerEmail">Email</div>
                        <div className="headerName">First Name</div>
                        <div className="headerName">Last Name</div>
                        <div className="headerPhone">Phone #</div>
                        <div className='text-center headerCheck'>CARB Certified</div>
                        <div className='text-center headerCheck'>Approved</div>
                        <div className='text-center headerCheck'>Active</div>
                     </div>;

        return (
            <div>
                <div id="sidebar">
                    <ul className="list-unstyled components">
                        <NavItem>
                            <NavLink tag={Link} className="sidebar-menu-item" to="/users">{type}Users</NavLink>
                        </NavItem>
                        {groupsMenu}
                        {rolesMenu}
                    </ul>
                </div>
                <div className="datatable-doc-demo">
                    <div className="card">
                        <DataTable id="dataTable" header={header} value={users} className="p-datatable-striped"
                            selectionMode="single" selection={this.state.user} onSelectionChange={e => this.openUserModal(e.value)} dataKey="id"
                            paginator rows={22}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" rowsPerPageOptions={[10, 20, 50]}
                            rowClassName={this.rowClassName} >
                            <Column className="username" field='userName' sortable filter filterPlaceholder="Search by username"></Column>
                                <Column className="email" field='email' sortable filter filterPlaceholder="Search by email"></Column>
                                <Column className="name" field='firstName' sortable filter filterPlaceholder="Search by first name" filterHeaderStyle={{ width: '10px' }}></Column>
                                <Column className="name" field='lastName' sortable filter filterPlaceholder="Search by last name"></Column>
                                <Column className="phone" field='phoneNumber'></Column>
                                <Column className="check" field='carbCertified' body={this.carbCertifiedTemplate}></Column>
                                <Column className="check" field='approved' body={this.approvedTemplate}></Column>
                                <Column className="check" field='active' body={this.activeTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
                <Modal isOpen={this.state.showUserModal} style={customUserStyles} contentLabel="User" >
                    <div className="modal-title"><h5>User Info</h5></div>
                    <div className="row pt-4">
                        <div className="col-6 pl-4 form-group">
                            <div className="row">
                                <label htmlFor="popupUserName" className="col-4 font-weight-bold">Username: </label>
                                <div className="col-8">
                                    <input type="text" id="popupUserName" tabIndex="1" className="wide-text-input" disabled={inputsDisabled} onChange={this.onUserNameChange} value={this.state.currentUser == null ? "" : this.state.currentUser.userName} />
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="popupPassword" className="col-4 font-weight-bold">Password: </label>
                                <div className="col-8">
                                    <input type="password" tabIndex="3" id="popupPassword" className="inputdefault" disabled={inputsDisabled} onChange={this.onPasswordChange} value={this.state.password} />
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="popupPasswordConfirm" className="col-4 font-weight-bold">Confirm Password: </label>
                                <div className="col-4">
                                    <input type="password" tabIndex="4" id="popupPasswordConfirm" className="inputdefault" disabled={!this.state.enablePasswordConfirm} onChange={this.onPasswordConfirmChange} value={this.state.passwordConfirm} />
                                </div>
                                <div className="col-4 mt-1"><img id="imgMatch" width="20" height="20" /></div>
                            </div>
                            <div className="row">
                                <label htmlFor="popupFirstName" className="col-4">First Name: </label>
                                <div className="col-8">
                                    <input type="text" tabIndex="5" id="popupFirstName" className="wide-text-input" disabled={inputsDisabled} onChange={this.onFirstNameChange} value={this.state.currentUser == null ? "" : this.state.currentUser.firstName} />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-4" htmlFor="popupEmail">Email: </label>
                                <div className="col-8">
                                    <input type="text" tabIndex="7" id="popupEmail" onChange={this.onEmailChange} disabled={inputsDisabled} className="wide-text-input" value={this.state.currentUser == null ? "" : this.state.currentUser.email} />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-4" htmlFor="popupPhone">Phone #: </label>
                                <div className="col-8">
                                    <input type="text" tabIndex="10" id="popupPhone" className="short-text-input" disabled={inputsDisabled} onChange={this.onPhoneChange} value={this.state.currentUser == null ? "" : this.state.currentUser.phoneNumber} maxLength="14" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 px-4">
                            <div className="row pb-2">
								<div className="col-5 divActive">
									<label>
										<input
											type="checkbox"
											tabIndex="2"
											checked={this.state.currentUser.active}
											onChange={this.handleModalActiveCheckboxChange}
											value={this.state.currentUser.id}
											className="form-check-input" />
										Active
									</label>
                                </div>
                                {divDelete}
                            </div>
                            <div className="row">&nbsp;</div>
                            <div className="row mb-2">
                                <div id="divMessage" className="col-12">&nbsp;</div>
                            </div>
                            <div className="row"><br /></div>
                            <div className="row">
                                <label className="col-4" htmlFor="popupLastName">Last Name: </label>
                                <div className="col-8">
                                    <input type="text" tabIndex="6" id="popupLastName" className="wide-text-input" disabled={inputsDisabled} onChange={this.onLastNameChange} value={this.state.currentUser == null ? "" : this.state.currentUser.lastName} />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-4" htmlFor="popupMaxMachines">Max # Machines: </label>
                                <div className="col-2">
                                    <input type="text" tabIndex="8" id="popupMaxMachines" className="short-text-input" disabled={inputsDisabled} onChange={this.onMaxUserMachinesChange} value={this.state.currentUser == null ? "" : this.state.currentUser.maxUserMachineCount} />
                                </div>
                                <label className="col-3" htmlFor="popupOfflineDays">Offline Days:</label>
                                <div className="col-2">
                                    <input type="text" tabIndex="9" id="popupOfflineDays" className="short-text-input" disabled={inputsDisabled} onChange={this.onOfflineLoginDaysChange} value={this.state.currentUser == null ? "" : this.state.currentUser.offlineLoginDays} />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-4" htmlFor="popupFax">Company: </label>
                                <div className="col-8">
                                    <input type="text" tabIndex="11" id="popupCompany" className="short-text-input" disabled={inputsDisabled} onChange={this.onCompanyChange} value={this.state.currentUser == null ? "" : this.state.currentUser.company} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {appTypeDiv}
                    <div className="row pl-4">
                        <div className="col-4">
							<div className="row pl-4">
								<label>
									<input
										type="checkbox"
										checked={this.state.currentUser.approved}
										disabled={inputsDisabled}
										onChange={this.handleModalApprovedCheckboxChange}
										value={this.state.currentUser.id}
										className="form-check-input" />
										Approved
								</label>
                            </div>
							<div className="row pl-4">
								<label>
									<input
										type="checkbox"
										checked={this.state.currentUser.carbCertified}
										disabled={inputsDisabled}
										onChange={this.handleModalCARBCertifiedCheckboxChange}
										value={this.state.currentUser.id}
										className="form-check-input" />
									CARB Certified
								</label>
                            </div>
                        </div>
                        {appTypeCheckboxesDiv}
                        <div className="col-5 text-right">
                            <button id="btnResetLocks" onClick={this.handleResetLocks}>Reset Locks</button>
                        </div>
                    </div>
                    {groupsDiv}
                    {rolesDiv}
                    <div className="pt-4">
                        <div className="row">
                            <div className="button-div"><button onClick={() => this.updateUser(this.state.currentUser)}>{this.state.addEditUserButtonLabel}</button></div>
                            <div className="button-div"><button onClick={this.closeUserModal}>Cancel</button></div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showMessageModal} style={customMessageStyles} contentLabel={this.state.messageLabel} >
                    <div>
                        <div className="row">
                            <div className="col-12 text-center mt-2 mb-3 font-weight-bold">
                                {this.state.messageMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 button-div text-center"><button onClick={this.closeMessageModal}>Ok</button></div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    render() {
        let radioButtonGroup = "";
        let roles = "";
        if (sessionStorage.hasOwnProperty("roles")) {
            roles = sessionStorage.getItem("roles").toLowerCase().split(",");
            if (roles.indexOf("admin") > -1) {
                radioButtonGroup = <div className="col-4 mt-1 divUserType">
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="radPowerCal"
                            value="PowerCal"
                            onChange={() => this.onAdminTypeChange("PowerCal")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">PowerCal</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="radCMR"
                            value="CMR"
                            onChange={() => this.onAdminTypeChange("CMR")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">CMR</label>
                    </div>
                </div>;
            } else {
                radioButtonGroup = <div className="col-2"></div>;
            }
        }

       return (
            <AuthContext.Consumer>
                {(context) => {
                    if (sessionStorage.hasOwnProperty("roles")) {
                        let roles = sessionStorage.getItem("roles").toLowerCase().split(",");
                        if (roles.indexOf("admin") > -1 || roles.indexOf("powercal support administrator") > -1 || roles.indexOf("powercal support tech") > -1 || roles.indexOf("cmr support administrator") > -1) {
                            let contents = this.state.showDeletedUsers ? this.renderUsersTable(this.state.allUsers) : this.renderUsersTable(this.state.displayUsers);
                            let type = this.state.type === "all" ? "" : this.state.type;

                            return (
                                <div>
                                    <div className="row">
                                        <div className="col-3 font-weight-bold"><h4>{type} Users</h4></div>
                                        {radioButtonGroup}
                                        <div className="col-3 mt-1 divShowDeletedUsers text-right">
                                            <Checkbox
                                                isSelected={this.state.showDeletedUsers}
                                                label="Show Deleted Users"
                                                onCheckboxChanged={this.handleShowDeletedCheckboxChange}
                                                value={this.state.showDeletedUsers}
                                                checkboxClasses="form-check-input" />
                                        </div>
                                        <div className="addNewUser col-2 mt-1 text-right" onClick={() => this.openUserModal()}>+ Add New User</div>
                                    </div>
                                    {contents}
                                </div>
                            );
                        } else {
                            return (
                                <Redirect to="/login?r=users" />
                            )
                        }
                    } else {
                        return (
                            <Redirect to="/login?r=users" />
                        )
                    }
                }}
            </AuthContext.Consumer>
        )
    }
}


export default Users;
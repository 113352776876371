import React, { Component } from 'react';
import { Form, Col, Row, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            passwordConfirm: '',
            validated: false,
            id: '',
            showAlert: false,
            alertMessage: '',
            messageType: '',
            passwordResetHash: ''
        };
    }

    UNSAFE_componentWillMount() {
        let search = window.location.search;
        let searchParams = new URLSearchParams(search);
        let params = {};
        for (var value of searchParams.keys()) {
            params[value] = searchParams.get(value);
        }
        this.setState({ email: params.email, jwt: params.key });
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }
    onPasswordConfirmChange = (e) => {
        this.setState({ passwordConfirm: e.target.value });
    }

    submitForm = (e) => {
        e.preventDefault();

        this.setState({
            showAlert: false,
            alertMessage: '',
            messageType: ''
        });

        //built in bootstrap form validation
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({ validated: true });
            return;
        }

        //check if passwords match
        if (this.refs.password.value !== this.refs.passwordConfirm.value) {
            this.setState({
                showAlert: true,
                alertMessage: "Passwords do not match",
                messageType: 'danger',
                password: '',
                passwordConfirm: '',
                validated: true
            });
            return;
        }

        //validate the password against password policy
        var regEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
        if (!regEx.test(this.refs.password.value)) {
            this.setState({
                showAlert: true,
                alertMessage: "Password must contain 1 lowercase letter, one uppsercase letter, 1 numeric character, and one special character",
                messageType: 'danger',
                password: '',
                passwordConfirm: '',
                validated: true
            });
            return;
        }

        fetch('resetuserpassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                newpassword: this.state.password,
                jwt: this.state.jwt
            })
        })
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        email: '', validated: false, password: '', passwordConfirm: '', showAlert: true,
                        alertMessage: "Password succesfully changed!", messageType: 'success'
                    });
                } else {
                    this.setState({
                        showAlert: true,
                        alertMessage: "Problem occurred while attempting to update your password.  Please contact Powerteq support.",
                        messageType: 'danger'
                    });
                }
            })
            .catch(error => {
                this.setState({
                    showAlert: true,
                    alertMessage: "Error occurred while attempting to update your password: " + error,
                    messageType: 'danger'
                });
            });
    }

    render() {
        return (
            <div className="container">
                <h1>Reset Password</h1>
                <hr />
                {this.state.showAlert ? <Alerter alertMessage={this.state.alertMessage} variant={this.state.messageType} /> : null}
                <Form noValidate validated={this.state.validated} method="post" onSubmit={this.submitForm}>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Email</Form.Label>
                        <Col sm={4}>
                            <Form.Control readOnly onChange={this.onEmailChange} value={this.state.email} required type="text" />
                            <Form.Control.Feedback type="invalid">Please provide an email</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Password</Form.Label>
                        <Col sm={4}>

                            <Form.Control ref="password" onChange={this.onPasswordChange} value={this.state.password} required type="password" />

                        </Col>
                        <Col>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <MDBIcon icon="info-circle" />
                            </OverlayTrigger>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Confirm Password</Form.Label>
                        <Col sm={4}>
                            <Form.Control ref="passwordConfirm" onChange={this.onPasswordConfirmChange} value={this.state.passwordConfirm} required type="password" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={{ span: 4, offset: 2 }}>
                            <Button type="submit">Reset password</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

function Alerter(props) {
    return (
        <Alert variant={props.variant}>
            {props.alertMessage}
        </Alert>
    );
}

function renderTooltip(props) {
    return <Tooltip {...props}>Password must contain 1 lowercase letter, one uppsercase letter, 1 numeric character, and one special character</Tooltip>;
}

export default ResetPassword;